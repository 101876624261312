import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import modules from "@/state/modules";
import server from "@/state/modules/server";
import user from "@/state/modules/user";
import CryptoJS from "crypto-js";

const vuexPersist = new VuexPersist({
  key: "data",
  storage: window.localStorage,
  modules: ["user"],
  // Encrypt data before saving
  saveState: (key, state, storage) => {
    const data = JSON.stringify(state);
    const cipherText = CryptoJS.AES.encrypt(
      data,
      process.env.VUE_APP_VUEX_PERSIST_KEY
    ).toString();
    storage.setItem(key, cipherText);
  },
  // Decrypt data after loading
  restoreState: (key, storage) => {
    try {
      const cipherText = storage.getItem(key);
      if (!cipherText) {
        return {};
      }
      const bytes = CryptoJS.AES.decrypt(
        cipherText,
        process.env.VUE_APP_VUEX_PERSIST_KEY
      );
      const data = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(data);
    } catch (error) {
      console.error("Error restoring Vuex store:", error);
      return {};
    }
  },
});

const store = createStore({
  modules: {
    ...modules,
    server,
    user,
  },
  plugins: [vuexPersist.plugin],
  strict: process.env.NODE_ENV !== "production",
});

export default store;
