export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/app/login.vue"),
  },
  {
    path: "/delete",
    name: "delete",
    component: () => import("@/views/app/deleteaccount.vue"),
  },
  {
    path: "/terminos",
    name: "terminos",
    component: () => import("@/views/app/terminos.vue"),
  },
  {
    path: "/contacto",
    name: "contact",
    component: () => import("@/views/app/contact.vue"),
  },
  {
    path: "/auth/login",
    name: "login",
    component: () => import("@/views/app/login.vue"),
  },
  {
    path: "/auth/logout",
    name: "logout",
    component: () => import("../views/app/logout.vue"),
  },
  {
    path: "/auth/register",
    name: "register",
    component: () => import("@/views/app/register.vue"),
  },
  {
    path: "/dashboard/main",
    name: "dashboard",
    component: () => import("@/views/app/dashboard/main.vue"),
  },
  {
    path: "/dashboard/historyfleet",
    name: "historyfleet",
    component: () => import("@/views/app/dashboard/historyFleet.vue"),
  },
  {
    path: "/dashboard/tracker/:imei",
    name: "tracker",
    component: () => import("@/views/app/dashboard/tracker.vue"),
  },
  {
    path: "/dashboard/history/:imei",
    name: "history",
    component: () => import("@/views/app/dashboard/history.vue"),
  },
  {
    path: "/dashboard/profile/",
    name: "profile",
    component: () => import("@/views/app/dashboard/profile.vue"),
  },
  {
    path: "/dashboard/fleetmap/:id",
    name: "fleetmap",
    component: () => import("@/views/app/dashboard/fleetmap.vue"),
  },
];
