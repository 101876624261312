import axios from "axios";

const state = {
  status: null,
};

const getters = {
  serverStatus: (state) => state.status,
};

const mutations = {
  SET_SERVER_STATUS: (state, status) => {
    state.status = status;
  },
};

const actions = {
  async fetchServerStatus({ commit }) {
    try {
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "server_status/"
      );
      commit("SET_SERVER_STATUS", response.data.status);
    } catch (error) {
      console.error("Error fetching server status:", error);
      commit("SET_SERVER_STATUS", null);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
