// Import necessary modules from Vue ecosystem
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";

// Import necessary plugins
import * as EvaIcons from "@stefandesu/eva-icons-vue";
import router from "./router";
import ActionCableVue from "actioncable-vue";
import BootstrapVue3 from "bootstrap-vue-3";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import Maska from "maska";

// Import CSS and SCSS files
import "sweetalert2/dist/sweetalert2.min.css";
import "@vueform/slider/themes/default.css";
import "./assets/scss/custom/plugins/mermaid.min.css";
import "./assets/scss/app.scss";

// Extra UI Elements
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

// Import Vuex store
import store from "./state/store";

// Import Moment and Localize to Mexico
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// Set Mexico Time Zone
moment.tz.setDefault("America/Mexico_City");

// Define WebSocket options for ActionCableVue
const websocketOptions = {
  debug: true,
  debugLevel: "error",
  connectionUrl: "http://localhost:8080/",
  connectImmediately: false,
};

// Import locale messages for Spanish
import messages from "./assets/locale/es.json";

// Setup internationalization (i18n) with Spanish as the default and fallback locale
const i18n = createI18n({
  legacy: true,
  locale: "es",
  fallbackLocale: "es",
  messages: {
    es: messages,
  },
});

// Create Vue app and use necessary plugins, router, Vuex store and i18n
createApp(App)
  .use(LoadingPlugin) // Use LoadingPlugin
  .use(store) // Use Vuex store
  .use(i18n) // Use internationalization
  .use(EvaIcons) // Use Eva Icons
  .use(router) // Use Vue Router
  .use(BootstrapVue3) // Use BootstrapVue
  .use(VueApexCharts) // Use Apex Charts
  .use(vClickOutside) // Use vClickOutside
  .use(Maska) // Use Maska for input masking
  .use(ActionCableVue, websocketOptions) // Use ActionCableVue for WebSocket communication
  .mount("#app"); // Mount the app to the DOM
