import axios from "axios";
import router from "@/router";

const state = {
  userInfo: null,
  accessToken: null,
};

const getters = {
  getUserInfo: (state) => state.userInfo,
  getAccessToken: (state) => state.accessToken,
};

const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_ACCESS_TOKEN: (state, accessToken) => {
    state.accessToken = accessToken;
  },
};

const actions = {
  async registerUser({ commit }, { username, password, email, first_name, last_name, manager_id = 0 }) {
    try {
      const formatName = (name) =>
        name
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(" ");

      const sanitizeUsername = (username) => username.replace(/'/g, "''"); // SQL Injection prevention by escaping single quote

      first_name = formatName(first_name);
      last_name = formatName(last_name);
      username = sanitizeUsername(username);

      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      formData.append("email", email);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("manager_id", manager_id);

      const response = await axios.post(process.env.VUE_APP_API_URL + "register_user", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      commit("SET_USER_INFO", response.data);
      return true;
    } catch (error) {
      console.error("Error registering user:", error);
      commit("SET_USER_INFO", null);
      return false;
    }
  },

  async requestSword({ commit }, { username }) {
    try {
      const response = await axios.post(process.env.VUE_APP_API_URL + "request_sword", { username });
      commit("SET_USER_INFO", response.data);
    } catch (error) {
      console.error("Error requesting sword:", error);
      commit("SET_USER_INFO", null);
    }
  },

  async sword({ commit }, { user_id, secret_word }) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}sword?user_id=${user_id}&secret_word=${secret_word}`
      );
      commit("SET_ACCESS_TOKEN", response.data.access_token);
    } catch (error) {
      console.error("Error with sword:", error);
      commit("SET_ACCESS_TOKEN", null);
    }
  },

  async login({ commit }, { username, password, trust_device }) {
    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      formData.append("trust_device", trust_device);

      const response = await axios.post(`${process.env.VUE_APP_API_URL}login?trust_device=${trust_device}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      commit("SET_ACCESS_TOKEN", response.data.access_token);
      return true;
    } catch (error) {
      console.error("Error logging in:", error);
      commit("SET_ACCESS_TOKEN", null);
      return false;
    }
  },

  async logout({ commit }) {
    commit("SET_ACCESS_TOKEN", null);
    commit("SET_USER_INFO", null);
  },

  async userInformation({ commit, state }) {
    try {
      const response = await axios.post(process.env.VUE_APP_API_URL + "user_information", null, {
        headers: { Authorization: `Bearer ${state.accessToken}` },
      });
      commit("SET_USER_INFO", response.data);
    } catch (error) {
      commit("SET_USER_INFO", null);
      console.error("Error retrieving user information:", error);
      if (error.response.status === 401) {
        commit("SET_ACCESS_TOKEN", null);
        router.push({ name: "login" });
        return;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
